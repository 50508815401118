import React, { lazy, Suspense } from "react";
import "./style/common.css";

import {
    BrowserRouter as Router,
    Route,
    Navigate,
    Routes,
} from "react-router-dom";

const Index = lazy(() => import("./components/SysAdmin/Index"));
const KeytermManagementContainer = lazy(() =>
    import("./containers/SysAdmin/KeytermManagementContainer"),
);
const UsersContainer = lazy(() =>
    import("./containers/SysAdmin/UsersContainer"),
);
const IPEXCustomerContainer = lazy(() =>
    import("./containers/SysAdmin/IPEXCustomerContainer"),
);
const LogsContainer = lazy(() => import("./containers/SysAdmin/LogsContainer"));
const LanguageSynContainer = lazy(() =>
    import("./containers/SysAdmin/LanguageSynContainer"),
);
const DMSIndex = lazy(() => import("./components/DMS/index"));
const NewsFeedsStockTickerContainer = lazy(() =>
    import("./containers/DMS/NewsFeedsStockTickerContainer"),
);
const TransferCommentsContainer = lazy(() =>
    import("./containers/DMS/TransferCommentsContainer"),
);
const CompanyCompareContainer = lazy(() =>
    import("./containers/DMS/CompanyCompareContainer"),
);
const DMSMetricsContainer = lazy(() =>
    import("./containers/DMS/DMSMetricsContainer"),
);
const ManageWorldlinkSettings = lazy(() =>
    import("./components/DMS/ManageWorldlinkSettings"),
);
import "./App.css";
const routes = [
    {
        path: "/SysAdmin",
        component: <Index />,
        exact: true,
    },
    {
        path: "/SysAdmin/KeytermManagement",
        component: <KeytermManagementContainer />,
        exact: true,
    },
    {
        path: "/SysAdmin/UserManagement",
        component: <UsersContainer />,
        exact: true,
    },
    {
        path: "/SysAdmin/IPEXCustomers",
        component: <IPEXCustomerContainer />,
        exact: true,
    },
    {
        path: "/SysAdmin/LanguageSynonyms",
        component: <LanguageSynContainer />,
        exact: true,
    },
    {
        path: "/SysAdmin/Logs",
        component: <LogsContainer />,
        exact: true,
    },
    {
        path: "/DMS",
        component: <DMSIndex />,
        exact: true,
    },
    {
        path: "/DMS/NewsFeedsStockTicker",
        component: <NewsFeedsStockTickerContainer />,
        exact: true,
    },
    {
        path: "/DMS/TransferComments",
        component: <TransferCommentsContainer />,
        exact: true,
    },
    {
        path: "/DMS/CompanyCompare",
        component: <CompanyCompareContainer />,
        exact: true,
    },
    {
        path: "/DMS/DMSMetrics",
        component: <DMSMetricsContainer />,
        exact: true,
    },
    {
        path: "/DMS/ManageWorldlinkSettings",
        component: <ManageWorldlinkSettings />,
        exact: true,
    },
];
function App() {
    return (
        <div className="container-fluid" style={{ padding: "inherit" }}>
            <Router>
                <Suspense>
                    <Routes>
                        {routes.map((route, index) => (
                            <Route
                                key={index}
                                exact
                                path={route.path}
                                element={route.component}
                            />
                        ))}
                        <Route
                            path="/"
                            element={<Navigate replace to="/SysAdmin" />}
                        />
                    </Routes>
                </Suspense>
            </Router>
        </div>
    );
}
export default App;
