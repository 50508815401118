const initialState = {
    langList: null,
};

const LanguageSynonymsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_LANGUAGE_DATA_SUCCESS":
            return {
                ...state,
                langList: action.payload,
            };
        case "CREATE_LANGUAGE_DATA_SUCCESS":
            return {
                ...state,
            };
        case "UPDATE_LANGUAGE_DATA_SUCCESS":
            return {
                ...state,
            };
        case "DELETE_LANGUAGE_DATA_SUCCESS":
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default LanguageSynonymsReducer;
