//root reducer file
import { combineReducers } from "redux";
import keyTermItems from "./SysAdmin/keytermReducer";
import {
    userApiReducer,
    userPermissionApiReducer,
    userRoleApiReducer,
} from "./SysAdmin/usersReducer";
import ipexCustomersApiReducer from "./SysAdmin/ipexCustomersReducer";
import logsApiReducer from "./SysAdmin/logsReducer";
import LanguageSynonymsReducer from "./SysAdmin/LanguageSynonymsReducer";
import stockTickerReducer from "./DMS/newsFeedsStockTickerReducer";
import companyCompareReducer from "./DMS/CompanyCompareReducer";
import DMSMetricsReducer from "./DMS/DMSMetricsReducer";

export default combineReducers({
    keytermApiData: keyTermItems,
    userApiData: userApiReducer,
    userRoleApiData: userRoleApiReducer,
    userPermissionApiData: userPermissionApiReducer,
    ipexCustomersApiData: ipexCustomersApiReducer,
    logsApiData: logsApiReducer,
    langApiData: LanguageSynonymsReducer,
    stockApiData: stockTickerReducer,
    companyApiData: companyCompareReducer,
    DMSMetricsApiCata: DMSMetricsReducer,
});
