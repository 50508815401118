const initialState = {
    list: null,
};

// Reducer function
const ipexCustomersApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "IPEX_FETCH_DATA_SUCCESS":
            return {
                ...state,
                list: action.payload,
            };
        case "IPEX_UPDATE_DATA_SUCCESS":
            return {
                ...state,
                list: action.payload,
            };
        default:
            return state;
    }
};

export default ipexCustomersApiReducer;
