const initialState = {
    companyList: null,
    companyDetailsOne: null,
    companyDetailsTwo: null,
    namingConventionOne: null,
    namingConventionTwo: null,
};

const companyCompareReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_COMPANY_LIST_DATA_SUCCESS":
            return {
                ...state,
                companyList: action.payload,
            };
        case "GET_COMPANY_DETAILS_ONE_DATA_SUCCESS":
            return {
                ...state,
                companyDetailsOne: action.payload,
            };
        case "GET_COMPANY_DETAILS_TWO_DATA_SUCCESS":
            return {
                ...state,
                companyDetailsTwo: action.payload,
            };
        case "GET_COMPANY_NAMING_ONE_DATA_SUCCESS":
            return {
                ...state,
                namingConventionOne: action.payload,
            };
        case "GET_COMPANY_NAMING_TWO_DATA_SUCCESS":
            return {
                ...state,
                namingConventionTwo: action.payload,
            };
        default:
            return state;
    }
};
export default companyCompareReducer;
