const initialState = {
    list: null,
    roleList: null,
    permissionList: null,
    IsProfileFlaggingEnabled: 0,
};

// Reducer function
export const userApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_USER_DATA_SUCCESS":
            return {
                ...state,
                list: action.payload,
            };
        case "ADD_NEW_USER_DATA_SUCCESS":
            return {
                ...state,
            };
        case "UPDATE_USER_DATA_SUCCESS":
            return {
                ...state,
            };
        case "GET_SINGLE_USER_DATA_SUCCESS":
            return {
                ...state,
            };
        case "GET_REDO_NAMEGEN_SUCCESS":
            return {
                ...state,
                IsProfileFlaggingEnabled: action.payload,
            };
        case "UPDATE_REDO_NAMEGEN_SUCCESS":
            return {
                ...state,
            };
        default:
            return state;
    }
};
export const userRoleApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_USER_ROLE_DATA_SUCCESS":
            return {
                ...state,
                roleList: action.payload,
            };
        default:
            return state;
    }
};
export const userPermissionApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_USER_PERMISSION_DATA_SUCCESS":
            return {
                ...state,
                permissionList: action.payload,
            };
        default:
            return state;
    }
};
