const initialState = {
    requisitionList: null,
    companyList: null,
    recruiterList: null,
    rejectReasonCodesList: null,
    acceptedReportList: null,
    rejectedReportList: null,
    acceptedRejectedList: null,
    timeLogsList: null,
    empList: null,
    averageTimeToFillList: null,
    averageTimeToFillQuarterlyList: null,
};

const DMSMetricsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_REQUISITION_DATA_SUCCESS":
            return {
                ...state,
                requisitionList: action.payload,
            };
        case "GET_DMS_COMPANY_LIST_DATA_SUCCESS":
            return {
                ...state,
                companyList: action.payload,
            };
        case "GET_RECRUITERS_DATA_SUCCESS":
            return {
                ...state,
                recruiterList: action.payload,
            };
        case "GET_REJECT_REASON_CODES_DATA_SUCCESS":
            return {
                ...state,
                rejectReasonCodesList: action.payload,
            };
        case "GET_OFFER_ACCEPTED_REPORT_DATA_SUCCESS":
            return {
                ...state,
                acceptedReportList: action.payload,
            };
        case "GET_OFFER_REJECTED_REPORT_DATA_SUCCESS":
            return {
                ...state,
                rejectedReportList: action.payload,
            };
        case "GET_OFFER_ACCEPTED_REJECTED_REPORT_DATA_SUCCESS":
            return {
                ...state,
                acceptedRejectedList: action.payload,
            };
        case "GET_TIME_LOGS_DATA_SUCCESS":
            return {
                ...state,
                timeLogsList: action.payload,
            };
        case "GET_EMP_DATA_SUCCESS":
            return {
                ...state,
                empList: action.payload,
            };
        case "GET_AVERAGE_TIME_TO_FILL_DATA_SUCCESS":
            return {
                ...state,
                averageTimeToFillList: action.payload,
            };
        case "GET_AVERAGE_TIME_TO_FILL_QUARTER_DATA_SUCCESS":
            return {
                ...state,
                averageTimeToFillQuarterlyList: action.payload,
            };
        default:
            return state;
    }
};
export default DMSMetricsReducer;
