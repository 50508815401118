const initialState = {
    list: null,
};

const keyTermItems = (state = initialState, action) => {
    switch (action.type) {
        case "SYS_ADMIN_FETCH_DATA_SUCCESS":
            return {
                ...state,
                list: action.payload,
            };
        case "SYS_ADMIN_CREATE_DATA_SUCCESS":
            return {
                ...state,
            };
        case "SYS_ADMIN_UPDATE_DATA_SUCCESS":
            return {
                ...state,
            };
        case "SYS_ADMIN_DELETE_DATA_SUCCESS":
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default keyTermItems;
