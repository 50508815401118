const initialState = {
    empList: null,
    logsList: null,
};

// Reducer function
export const logsApiReducer = (state = initialState, action) => {
    switch (action.type) {
        case "FETCH_EMPLOYEE_LIST_SUCCESS":
            return {
                ...state,
                empList: action.payload,
            };
        case "FETCH_LOGS_LIST_SUCCESS":
            return {
                ...state,
                logsList: action.payload,
            };
        default:
            return state;
    }
};

export default logsApiReducer;
