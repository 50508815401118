const initialState = {
    stockList: null,
    newsFeedList: null,
};

const stockTickerReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_STOCK_TICKER_DATA_SUCCESS":
            return {
                ...state,
                stockList: action.payload,
            };
        case "CREATE_STOCK_TICKER_DATA_SUCCESS":
            return {
                ...state,
            };
        case "UPDATE_STOCK_TICKER_DATA_SUCCESS":
            return {
                ...state,
            };
        case "DELETE_STOCK_TICKER_DATA_SUCCESS":
            return {
                ...state,
            };
        case "GET_NEWSFEED_DATA_SUCCESS":
            return {
                ...state,
                newsFeedList: action.payload,
            };
        case "CREATE_NEWSFEED_DATA_SUCCESS":
            return {
                ...state,
            };
        case "UPDATE_NEWSFEED_DATA_SUCCESS":
            return {
                ...state,
            };
        case "DELETE_NEWSFEED_DATA_SUCCESS":
            return {
                ...state,
            };
        default:
            return state;
    }
};
export default stockTickerReducer;
